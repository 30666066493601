import {FC, PropsWithChildren, useMemo} from "react";
import {MediaQueries} from "../../utils/MediaQueries";
import MaterialSymbol from "../MaterialSymbol/MaterialSymbol";
import Typo from "../Typo/Typo";
import {useThemeContext} from "../../contexts/ThemeContext";

interface IProps extends PropsWithChildren {
    /**
     * At what point to use the fullscreen type
     */
    breakpoint: "compact" | "medium" | "expanded" | "large" | "extraLarge"
    type: "basic" | "fullScreen"
    onCloseClick: () => void
    show: boolean
    isLoading?: boolean
    showShadow?: boolean
    headline?: string
    className?: string
}

const Dialog: FC<IProps> = (
    {
        type,
        breakpoint,
        onCloseClick,
        show,
        isLoading,
        showShadow,
        headline,
        className,
        children
    }
) => {
    const baseClassName = "dialog"

    const {getThemeClassName} = useThemeContext()

    const fullScreen = useMemo(() => type === "fullScreen" || MediaQueries[breakpoint](), [breakpoint, type])

    return (
        <div
            className={`
                ${baseClassName}
                ${baseClassName + (fullScreen ? "--full-screen" : "--basic")}
                ${show && `${baseClassName}--show`}
                ${getThemeClassName(baseClassName)} 
                ${className}
            `}
        >

            <div className={`${baseClassName}__inner`}>
                {isLoading && <div className={`${baseClassName}__loader-container`}>
                    <span className={`${baseClassName}__loading-span`}></span>
                </div>}
                {
                    fullScreen &&
                    <div
                        className={`${baseClassName}__header ${showShadow && (`${baseClassName}__header` + "--shadow")}`}>
                        <MaterialSymbol name={"close"} className={`${baseClassName}__close`} onClick={onCloseClick}/>

                        <Typo.Title className={`${baseClassName}__title`}>{headline}</Typo.Title>
                    </div>
                }
                <div className={`${baseClassName}__content`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Dialog