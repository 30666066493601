import React, {FC, PropsWithChildren} from "react";
import Sidebar from "./Sidebar/Sidebar";
import {useThemeContext} from "../../../contexts/ThemeContext";
import AppHeader from "./AppHeader/AppHeader";
import SideSheet from "./Sidesheet/SideSheet";
import {useSidebar} from "../../../contexts/SidebarContext";
import {useSideSheet} from "../../../contexts/SideSheetContext";
import AuthOverlay from "../../../components/AuthOverlay/AuthOverlay";

interface IProps extends PropsWithChildren {

}

const AppContainer: FC<IProps> = ({children}) => {
    const {getThemeClassName} = useThemeContext()

    const baseClassName = "app-container"

    const {isOpen: isSidebarOpen, closeSidebar} = useSidebar()
    const {isOpen: isSideSheetOpen, closeSideSheet} = useSideSheet()

    const onClickShade = () => {
        closeSidebar()
        closeSideSheet()
    }

    return (
        <>
            <AuthOverlay/>
            <div className={`${baseClassName} ${getThemeClassName("app-container")}`}>
                <div
                    className={`${baseClassName}__shade ${(isSidebarOpen || isSideSheetOpen) && baseClassName + "__shade--visible"}`}
                    onClick={onClickShade}></div>

                <Sidebar/>

                <div id={`dialog`}/>

                <div className={`${baseClassName}__page-container`}>
                    <AppHeader/>

                    <div className={`${baseClassName}__content`}>
                        {children}

                        <SideSheet/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppContainer