import {FC, useEffect, useRef} from "react";
import {useThemeContext} from "../../../../contexts/ThemeContext";
import {useSideSheet} from "../../../../contexts/SideSheetContext";

const SideSheet: FC = () => {
    const ref = useRef<HTMLDivElement>(null)

    const baseClassName = "side-sheet"

    const {setPortal} = useSideSheet()
    const {getThemeClassName} = useThemeContext()
    const {isOpen} = useSideSheet()

    useEffect(() => {
        if (ref.current) setPortal(ref.current)
    }, [ref.current]);

    return (
        <div
            className={`${baseClassName} ${getThemeClassName(baseClassName)} ${baseClassName}--${isOpen ? "open" : "closed"}`}
            ref={ref}
        >
        </div>
    )
}

export default SideSheet