import React, {FC, useState} from "react";
import TonalButton from "../../../../../../../../components/Buttons/TonalButton/TonalButton";
import MaterialSymbol from "../../../../../../../../components/MaterialSymbol/MaterialSymbol";
import Typo from "../../../../../../../../components/Typo/Typo";
import {useAuth} from "../../../../../../../Auth/slices/authSlice";
import {useGetCustomerQuery} from "../../../../../../../Customers/redux/customersApi";
import CustomerInfoDialog from "./components/CustomerInfoDialog";

interface IProps {

}

const AddressInfo: FC<IProps> = () => {
    const baseClassName = "billing-address-info"

    const {userInfo} = useAuth()

    const {
        data: customer
    } = useGetCustomerQuery(
        userInfo?.profile.customerId ?? "",
        {
            skip: !userInfo
        }
    )

    const [showDialog, setShowDialog] = useState(false)

    return (
        <div className={`${baseClassName}`}>
            <Typo.Title className={`${baseClassName}__title`}>Contact</Typo.Title>
            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Business type</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>
                    {
                        customer?.type
                        ? customer?.type[0].toUpperCase() + customer?.type.slice(1)
                        : "-"
                    }
                </Typo.Body>
            </div>
            
            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>(COMPANY) NAME</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.name ?? "-"}</Typo.Body>
            </div>
            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Phone</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.phoneNumber ?? "-"}</Typo.Body>
            </div>

            <Typo.Title className={`${baseClassName}__title`}>Address</Typo.Title>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Street</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>
                    {customer?.address?.street ?? "-"} {customer?.address?.houseNumber}{customer?.address?.suffix}
                </Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Zipcode/postal code</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.address?.postalCode ?? "-"}</Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>City</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.address?.city ?? "-"}</Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>State/province</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.address?.state ?? "-"}</Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Country</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.address?.country ?? "-"}</Typo.Body>
            </div>

            <Typo.Title size={"large"}>Invoiced to</Typo.Title>
            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Street</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>
                    {customer?.invoiceAddress?.street ?? "-"} {customer?.invoiceAddress?.houseNumber}{customer?.invoiceAddress?.suffix}
                </Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Zipcode/postal code</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.invoiceAddress?.postalCode ?? "-"}</Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>City</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.invoiceAddress?.city ?? "-"}</Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>State/province</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.invoiceAddress?.state ?? "-"}</Typo.Body>
            </div>

            <div className={`${baseClassName}__address-line`}>
                <Typo.Label className={`${baseClassName}__title`}>Country</Typo.Label>
                <Typo.Body className={`${baseClassName}__title`}>{customer?.invoiceAddress?.country ?? "-"}</Typo.Body>
            </div>

            <TonalButton
                color={"blue"}
                textType={"icon-text"}
                className={`${baseClassName}__edit-button`}
                onClick={() => setShowDialog(true)}
            >
                <MaterialSymbol name={"edit"}/>

                Edit
            </TonalButton>

            <CustomerInfoDialog showDialog={showDialog} setShowDialog={setShowDialog}/>
        </div>
    )
}

export default AddressInfo