import {FC, PropsWithChildren} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

interface IProps extends PropsWithChildren {
    className?: string
    width?: string
    height?: string
    animationDelay?: string
}

const Skeleton: FC<IProps> = (
    {
        children,
        className,
        height,
        width,
        animationDelay
    }
) => {
    const {getThemeClassName} = useThemeContext()

    return (
        <div
            className={`skeleton ${getThemeClassName("skeleton")} ${className ?? ""}`}
            style={{
                width: width,
                height: height,
                animationDelay: animationDelay
            }}
        >
            <div className="skeleton__children">
                {children}
            </div>
        </div>
    )
}

export default Skeleton