import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";

interface IThemeContext {
    theme: string
    setTheme: (theme: string) => void
    getThemeClassName: (className: string) => string
}

const getLocalTheme = (): string => {
    const theme = localStorage.getItem("theme")

    if (!theme) localStorage.setItem("theme", "dark")

    return localStorage.getItem("theme") ?? "dark"
}

export const ThemeContext = createContext({} as IThemeContext)

export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [theme, _setTheme] = useState<string>(getLocalTheme());

    const getThemeClassName = useCallback((className: string) => {
        return className + "--" + theme
    }, [theme])

    const setTheme = (theme: string) => {
        localStorage.setItem("theme", theme)
        _setTheme(theme)
    }

    useEffect(() => {
        document.documentElement.classList.add(theme)
    }, [theme]);

    return (
        <ThemeContext.Provider
            value={{
                theme: theme,
                getThemeClassName,
                setTheme
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export const useThemeContext = () => useContext(ThemeContext)

/**
 * 
 * @param className A single class
 * @returns The class and the class with the theme modifier e.g. "container container--dark"
 */
export const useThemedClassName = (className: string) => {
    const { theme } = useThemeContext();

    return `${className} ${className}--${theme}`
}