import {FC, useMemo} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

interface IProps {
    value: string,
    label: string,
    selected: string
    onChange: (value: string) => void
}

const RadioButton: FC<IProps> = ({label, value, selected, onChange}) => {
    const {theme} = useThemeContext()

    const baseClassName = "radio-button"

    const isSelected = useMemo(() => selected === value, [selected, value])

    return (
        <div
            className={`${baseClassName} ${isSelected && `${baseClassName}--selected`} ${baseClassName}--${theme}`}
            onClick={() => onChange(value)}
        >
            <div className={`${baseClassName}__outer`}>
                <div className={`${baseClassName}__inner`}></div>
            </div>

            <p className={`${baseClassName}__label`}>{label}</p>
        </div>
    )
}

export default RadioButton