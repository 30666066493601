import TextButton from "components/Buttons/TextButton/TextButton";
import Loader from "components/Loader/Loader";
import Typo from "components/Typo/Typo";
import { useThemeContext } from "contexts/ThemeContext";
import { useCustomerId } from "features/Auth/hooks/useCustomerId";
import { useDeleteRegistrantMutation, useGetRegistrantQuery } from "features/Domains/api/registrantsApi";
import { DialogPortal } from "hooks/DialogPortal";
import { FC, useEffect } from "react";

interface IProps {
    id: string | undefined
    setId: (id: string | undefined) => void
}

const DeleteRegistrantDialog: FC<IProps> = ({ id, setId }) => {
    const baseClassName = "delete-registrant-dialog"

    const { getThemeClassName } = useThemeContext()

    const customerId = useCustomerId()

    const { data, isLoading } = useGetRegistrantQuery({
        customerId: customerId ?? "",
        registrantId: id ?? ""
    }, { skip: !id || !customerId })

    const [deleteAction, {
        reset,
        isSuccess: isDeleteSuccess,
        isLoading: isDeleteLoading
    }] = useDeleteRegistrantMutation();

    const onClose = () => {
        setId(undefined)

        reset()
    }

    useEffect(() => {
        if (isDeleteSuccess) {
            setId(undefined)
            reset()
        }
    }, [setId, reset, isDeleteSuccess])

    return (
        <DialogPortal
            content={
                <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
                    {
                        (!data || !customerId || isDeleteLoading) ? (
                            <div className={`${baseClassName}__loader`}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <Typo.Headline size={"small"} className={`${baseClassName}__title`}>Delete
                                    registrant</Typo.Headline>

                                <Typo.Body className={`${baseClassName}__body`}>Are you sure you want to delete
                                    "{data?.alias}"?</Typo.Body>

                                <div className={`${baseClassName}__buttons`}>
                                    <TextButton color={"blue"} onClick={onClose} textType={"text-only"}>
                                        Cancel
                                    </TextButton>

                                    <TextButton color={"blue"} textType={"text-only"}
                                        onClick={() => deleteAction({ customerId: customerId!, id: id! })}>
                                        Delete
                                    </TextButton>
                                </div>
                            </>
                        )
                    }
                </div>
            }
            type={"basic"}
            onCloseClick={() => setId(undefined)}
            show={!!id}
            isLoading={isLoading}
        />
    )
}

export default DeleteRegistrantDialog