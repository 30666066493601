import {createPortal} from "react-dom";
import {FC, ReactNode} from "react";
import Dialog from "../components/Dialog/Dialog";

interface IProps {
    content: ReactNode
    type: 'fullScreen' | 'basic'
    onCloseClick: () => void
    show: boolean
    isLoading: boolean
    /**
     * @default "compact"
     */
    breakpoint?: "medium" | "large" | "compact" | "expanded" | "extraLarge"
    showShadow?: boolean
    headline?: string
    className?: string
    key?: string
}

export const DialogPortal: FC<IProps> = (
    {
        content,
        breakpoint,
        type,
        onCloseClick,
        show,
        isLoading,
        showShadow,
        headline,
        className,
        key
    }
) => {
    const el = document.getElementById("dialog")

    if (!el) return <></>

    return (
        <>
            {
                createPortal(
                    <Dialog
                        breakpoint={breakpoint ?? "compact"}
                        className={className}
                        show={show}
                        isLoading={isLoading}
                        type={type}
                        headline={headline}
                        showShadow={showShadow}
                        onCloseClick={onCloseClick}
                    >
                        {content}
                    </Dialog>,
                    el,
                    key
                )
            }
        </>
    )
}