import {FC} from "react";
import TextButton from "../../../../../components/Buttons/TextButton/TextButton";
import MaterialIcon from "../../../../../components/MaterialIcon/MaterialIcon";
import Typo from "../../../../../components/Typo/Typo";
import OutlinedButton from "../../../../../components/Buttons/OutlinedButton/OutlinedButton";
import FilledButton from "../../../../../components/Buttons/FilledButton/FilledButton";
import {useUserInfo} from "../../../../../features/Auth/hooks/useUserInfo";
import {useUserContext} from "../../../../../contexts/UserContext";
import MaterialSymbol from "../../../../../components/MaterialSymbol/MaterialSymbol";
import {useSideSheet} from "../../../../../contexts/SideSheetContext";
import {useThemeContext} from "../../../../../contexts/ThemeContext";

const Account: FC = () => {
    const baseClassName = "account"

    const {closeSideSheet} = useSideSheet()
    const {getThemeClassName} = useThemeContext()

    const userInfo = useUserInfo()

    const onClickManageAccount = () => {
        let oauthUrl = process.env.REACT_APP_OAUTH_URL + "/account"

        if (oauthUrl) window.open(oauthUrl, "_blank", "noreferrer")
    }

    const {signOut} = useUserContext()

    return (
        <div
            key={baseClassName}
            className={`${baseClassName} ${getThemeClassName(baseClassName)}`}
        >
            <div>
                <div className={`${baseClassName}__close`}>
                    <TextButton color="blue" textType="icon-only" onClick={closeSideSheet}>
                        <MaterialSymbol name="close"/>
                    </TextButton>
                </div>

                <div className={`${baseClassName}__details`}>
                    <div className={`${baseClassName}__avatar-container`}>
                        <div className={`${baseClassName}__avatar`}>
                            <Typo.Display>{userInfo?.profile.givenName[0]}</Typo.Display>
                        </div>
                    </div>

                    <p className={`${baseClassName}__name`}>{userInfo?.profile.givenName} {userInfo?.profile.familyName}</p>

                    <p className={`${baseClassName}__email`}>{userInfo?.profile.email}</p>

                    <OutlinedButton
                        onClick={onClickManageAccount}
                        color={'blue'}
                        textType="icon-text"
                        className={`${baseClassName}__manage-button`}
                    >
                        <MaterialIcon name="open_in_new"/>
                        Manage account
                    </OutlinedButton>
                </div>
            </div>

            <div className={`${baseClassName}__buttons`}>
                <FilledButton
                    className={`${baseClassName}__logout`}
                    color={'blue'}
                    textType="icon-text"
                    onClick={signOut}
                >
                    <MaterialIcon name="logout"/>
                    Sign out
                </FilledButton>
            </div>
        </div>
    )
}

export default Account