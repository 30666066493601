import { createContext, FC, PropsWithChildren, useContext, useState } from "react";

interface IAppContainerContext {
    title: string
    setTitle: (title: string) => void
    backPath: string | undefined
    setBackPath: (path: string | undefined) => void
    clearBackPath: () => void
}

export const AppContainerContext = createContext({} as IAppContainerContext)

export const AppContainerContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [title, setTitle] = useState<string>("")
    const [backPath, setBackPath] = useState<string>()

    const clearBackAction = () => setBackPath(undefined)

    return (
        <AppContainerContext.Provider value={{
            title,
            setTitle,
            backPath: backPath,
            setBackPath: setBackPath,
            clearBackPath: clearBackAction
        }}>
            {children}
        </AppContainerContext.Provider>
    )
}

export const useAppContainer = () => useContext(AppContainerContext)