import {useThemeContext} from "../../../contexts/ThemeContext";
import {ReactComponent as Dark} from "../../../assets/vectors/404_dark.svg";
import {ReactComponent as Light} from "../../../assets/vectors/404_light.svg";

const NotFound = () => {
    const {theme, getThemeClassName} = useThemeContext()

    const baseClassName = "not-found"

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <div className={`${baseClassName}__content`}>
                <div className={`${baseClassName}__vector-container`}>
                    {theme === "light" ? <Light/> : <Dark/>}
                </div>

                <p className={`${baseClassName}__text`}>Whoops, this page doesn't exist...</p>
            </div>
        </div>
    )
}

export default NotFound