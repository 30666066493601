import FormMenu from "components/Form/components/FormMenu";
import { useThemedClassName } from "contexts/ThemeContext";
import { useCustomerId } from "features/Auth/hooks/useCustomerId";
import { useGetRegistrantsByCustomerIdQuery } from "features/Domains/api/registrantsApi";
import { Registrant } from "features/Domains/api/responses/Registrant";
import { useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";

interface IProps {
    name: string
}

function RegistrantsFormMenu<TSchema extends FieldValues> ({name}: IProps) {
    const baseClassName = "registrant-menu"
    const className = useThemedClassName(baseClassName)

    const [search, setSearch] = useState<string>("");

    const customerId = useCustomerId()

    const { data, isLoading } = useGetRegistrantsByCustomerIdQuery({
        page: 0,
        pageSize: 50,
        customerId: customerId ?? ""
    }, {
        skip: !customerId
    })

    const options = useMemo(() => {
        if(search === "") return data?.items ?? []
        return data?.items.filter(i => i.alias.toLowerCase().includes(search.toLowerCase())) ?? []
    }, [data?.items, search])

    return (
        <div className={`${className}`}>
            <FormMenu<Registrant, TSchema>
                isLoading={isLoading}
                name={name}
                label="Registrant"
                labelSelector={(v) => v.alias}
                valueSelector={(v) => v.id}
                options={options}
                closeOnOptionClick
                onSearch={setSearch}
                searchInterval={500}
            />
        </div>
    )
}

export default RegistrantsFormMenu