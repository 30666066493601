import {FC, useEffect, useMemo, useState} from "react";
import {useUserContext} from "../../contexts/UserContext";
import {useThemeContext} from "../../contexts/ThemeContext";
import {useAppSelector} from "../../stores/redux/hooks/useSelector";
import {AuthStatus} from "../../types/constants/AuthStatus";
import MaterialIcon from "../MaterialIcon/MaterialIcon";
import Loader from "../Loader/Loader";
import FilledButton from "../Buttons/FilledButton/FilledButton";
import MaterialSymbol from "../MaterialSymbol/MaterialSymbol";

const AuthOverlay: FC = () => {
    const {redirectToSignIn} = useUserContext()
    const {getThemeClassName} = useThemeContext()
    const [isClosed, setIsClosed] = useState<boolean>()

    const status = useAppSelector(state => state.auth.status)
    const baseClassName = "auth-overlay"

    const renderStateModifier = useMemo(() => {
        switch (status) {
            case AuthStatus.AUTHENTICATED:
                return baseClassName + "--success"
            case AuthStatus.ERROR:
                return baseClassName + "--error"
            default:
                return ""
        }
    }, [status])

    const renderIcon = useMemo(() => {
        switch (status) {
            case AuthStatus.AUTHENTICATED:
                return <MaterialSymbol name="lock_open"/>
            case AuthStatus.ERROR:
                return <MaterialSymbol name="warning"/>
            default:
                return <MaterialSymbol name="lock"/>
        }
    }, [status])

    const renderClosedModifier = useMemo(() => isClosed ? baseClassName + "--closed" : "", [isClosed])

    useEffect(() => {
        let timeout: number;

        if (status === AuthStatus.AUTHENTICATED) {
            timeout = window.setTimeout(() => {
                setIsClosed(true)
            }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [status, setIsClosed])

    return (
        <div className={`
            ${baseClassName} 
            ${renderStateModifier} 
            ${getThemeClassName(baseClassName)}
            ${renderClosedModifier}
        `}>
            <div className={`${baseClassName}__loading-container`}>
                <div className={`${baseClassName}__icon-container`}>
                    {renderIcon}
                </div>

                <Loader
                    loaded={status === AuthStatus.AUTHENTICATED}
                    error={status === AuthStatus.ERROR}
                />
            </div>

            {
                status === AuthStatus.ERROR &&
                <div className={`${baseClassName}__error`}>
                    <div>
                        <p>Something went wrong while trying to log you in. <br/> Sorry for the inconvenience...</p>
                    </div>

                    <FilledButton
                        color="red"
                        textType="icon-text"
                        iconLocation="behind"
                        onClick={() => redirectToSignIn(true)}
                    >
                        Try again
                        <MaterialIcon name={"arrow_forward"}/>
                    </FilledButton>
                </div>
            }
        </div>
    )
}

export default AuthOverlay