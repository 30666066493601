import React, {DetailedHTMLProps, FC, HTMLAttributes} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

interface InternalProps extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
    type: "display" | "headline" | "title" | "body" | "label"
    size: "small" | "medium" | "large"
}

const InternalComponent: FC<InternalProps> = ({children, type, size, ...props}) => {
    const {getThemeClassName} = useThemeContext()

    const baseClassName = "text"

    return (
        <p {...props}
           className={`${baseClassName} ${getThemeClassName(baseClassName)} ${getThemeClassName(type)} ${type} ${type}--${size} ${props.className}`}>
            {children}
        </p>
    )
}

interface TypoProps extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
    /**
     * @default "large"
     */
    size?: "small" | "medium" | "large"
}

class Typo {
    static Display: FC<TypoProps> = ({size = "large", ...props}) => {
        return <InternalComponent {...props} type={"display"} size={size}/>
    }

    static Headline: FC<TypoProps> = ({size = "large", ...props}) => {
        return <InternalComponent {...props} type={"headline"} size={size}/>
    }

    static Title: FC<TypoProps> = ({size = "large", ...props}) => {
        return <InternalComponent {...props} type={"title"} size={size}/>
    }

    static Body: FC<TypoProps> = ({size = "large", ...props}) => {
        return <InternalComponent {...props} type={"body"} size={size}/>
    }

    static Label: FC<TypoProps> = ({size = "large", ...props}) => {
        return <InternalComponent {...props} type={"label"} size={size}/>
    }
}

export default Typo