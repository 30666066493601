import React, {ChangeEvent, DetailedHTMLProps, FC, forwardRef, InputHTMLAttributes, useEffect, useRef} from "react";
import MaterialSymbol from "../MaterialSymbol/MaterialSymbol";
import {useThemeContext} from "../../contexts/ThemeContext";

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
}

const Checkbox: FC<IProps> = forwardRef<HTMLInputElement, IProps>(({className, ...props}, ref) => {
    const baseClassName = "checkbox"

    const localRef = useRef<HTMLInputElement | null>();

    const {getThemeClassName} = useThemeContext()

    const onClickDiv = () => localRef.current?.click()

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)} ${className}`}>
            <input type={"checkbox"} ref={(e) => {
                if (typeof ref === 'function') ref(e)
                else if (ref != null) ref.current = e
                
                localRef.current = e
            }} {...props}/>

            <div className={`${baseClassName}__container`} onClick={onClickDiv}>
                <MaterialSymbol name={"check"} className={`${baseClassName}__icon`}/>
            </div>
        </div>
    )
})

export default Checkbox