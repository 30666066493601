import {FC, useMemo} from "react";
import {useThemeContext} from "../../../../contexts/ThemeContext";

import GitLabIcon from "../../../../assets/icons/gitlab-icon.png";

interface IProps {
    name: string
}

const ManagedAppIcon: FC<IProps> = ({name}) => {
    const baseClassName = "managed-app-icon"

    const {getThemeClassName} = useThemeContext()

    const Icon: FC = useMemo(() => {
        switch (name) {
            case "GitLab": {
                return () => <img src={GitLabIcon} alt=""/>
            }
            default:
                return () => <></>
        }
    }, [name])

    return (
        <span className={`
            ${baseClassName}
            ${getThemeClassName(baseClassName)}
        `}>
            <Icon/>
        </span>
    )
}

export default ManagedAppIcon