import React, { FC } from "react";
import { useThemeContext } from "../../../../contexts/ThemeContext";
import Pane from "../../../../components/Pane/Pane";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import { useNavigate } from "react-router-dom";
import { RoutingDefinition } from "../../../../app/routes/RoutingDefinition";
import ManagedAppOption from "./components/ManagedAppOption";
import GitLabLogo from "../../../../assets/images/gitlab_logo.svg";
import { MediaQueries } from "../../../../utils/MediaQueries";

const SelectManagedAppPage: FC = () => {
    const baseClassName = "select-managed-app-page"

    const { getThemeClassName } = useThemeContext()
    const navigate = useNavigate()

    const backPath = RoutingDefinition.managedApps.navigationLink!
    const onBackClick = () => navigate(backPath)

    const onClick = (name: string) => {
        navigate(RoutingDefinition.createManagedApp.path + `?name=${name}`)
    }

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Pane title="Managed Apps" backPath={MediaQueries.medium() ? backPath : undefined}>
                <PaneHeader
                    backButton={!MediaQueries.medium()}
                    title={"Select"}
                    parentTitle={!MediaQueries.medium() && "Managed Apps"}
                    onBackClick={() => navigate(RoutingDefinition.managedApps.navigationLink!)}
                />

                <div className={`${baseClassName}__content`}>
                    <div className={`${baseClassName}__apps`}>
                        <ManagedAppOption
                            onClick={onClick}
                            image={GitLabLogo}
                            label={"GitLab"}
                            name={"GitLab"}
                            description={"GitLab is a comprehensive DevOps platform providing version control, CI/CD, project management, and collaboration tools for software development teams."}
                        />
                    </div>
                </div>
            </Pane>
        </div>
    )
}

export default SelectManagedAppPage