import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { RoutingDefinition } from "app/routes/RoutingDefinition";
import RegisterDomainNameDialog from "features/Domains/components/RegisterDomainNameDialog/RegisterDomainNameDialog";
import { useThemeContext } from "contexts/ThemeContext";
import { useCustomerId } from "features/Auth/hooks/useCustomerId";
import { useGetDomainNamesByCustomerIdQuery } from "features/Domains/api/domainNamesApi";
import { DomainName } from "features/Domains/api/responses/DomainName";
import Table from "components/Table/Table";

const DomainNamesTab = () => {
    const baseClassName = "domain-names-tab"

    const { getThemeClassName } = useThemeContext()

    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const pageSize = 10
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])

    const customerId = useCustomerId()

    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useGetDomainNamesByCustomerIdQuery({
        customerId: customerId ?? "",
        page: page,
        pageSize: pageSize
    }, {
        skip: !customerId
    })

    const onDomainNameClick = (item: DomainName) => {
        navigate(RoutingDefinition.domainName.navigationLink! + `/${item.id}`)
    }

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <RegisterDomainNameDialog />

            <Table<DomainName>
                className={`${baseClassName}__table`}
                columns={[
                    {
                        name: "Domain name",
                        className: `${baseClassName}__domain-name`,
                        render: (item) => <p>{item.sld}.{item.tld}</p>
                    },
                    {
                        name: "Registrant",
                        className: `${baseClassName}__registrant`,
                        render: (item) => <p>{item.registrantAlias}</p>
                    },
                    {
                        name: "Registered at",
                        className: `${baseClassName}__created-at`,
                        render: (item) => {
                            const date = new Date(item.createdAt)

                            return <p>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</p>
                        }
                    }
                ]}
                onItemClick={onDomainNameClick}
                page={page}
                pageSize={pageSize}
                totalItems={data?.totalItems ?? 0}
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                items={data}
            />
        </div>
    )
}

export default DomainNamesTab