import {FC, PropsWithChildren} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

const Label: FC<PropsWithChildren> = ({children}) => {
    const baseClassName = "label-text"

    const {getThemeClassName} = useThemeContext()

    return (
        <p className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>{children}</p>
    )
}

export default Label