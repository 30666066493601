import FilledButton from "components/Buttons/FilledButton/FilledButton";
import TextButton from "components/Buttons/TextButton/TextButton";
import FormMenu from "components/Form/components/FormMenu";
import FormOutlinedTextField from "components/Form/components/FormOutlinedTextField";
import MaterialSymbol from "components/MaterialSymbol/MaterialSymbol";
import Typo from "components/Typo/Typo";
import { useThemedClassName } from "contexts/ThemeContext";
import { useCustomerId } from "features/Auth/hooks/useCustomerId";
import { RegisterDomainNameSchema, RegisterDomainNameSchemaType } from "features/Domains/api/commands/RegisterDomainNameSchema";
import { useRegisterDomainNameMutation } from "features/Domains/api/domainNamesApi";
import RegistrantsFormMenu from "features/Domains/components/RegistrantsMenu/RegistrantFormMenu";
import { DialogPortal } from "hooks/DialogPortal";
import { useAppForm } from "hooks/useAppForm";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { MediaQueries } from "utils/MediaQueries";

const RegisterDomainNameDialog: FC = () => {
    const baseClassName = "register-domain-name-dialog"
    const className = useThemedClassName(baseClassName)

    const [showDialog, setShowDialog] = useState<boolean>(false)

    const customerId = useCustomerId()
    const initData = useInitData(customerId ?? "")

    const {
        form,
        formReset,
        onFormSubmit,
        isLoading,
        isSuccess
    } = useAppForm(
        initData,
        RegisterDomainNameSchema,
        useRegisterDomainNameMutation
    )

    const onClickCreate = () => setShowDialog(true)
    const onCloseClick = useCallback(() => {
        formReset()
        setShowDialog(false)
    }, [formReset, setShowDialog])

    const tldOptions = ["nl", "com", "be", "eu"]

    useEffect(() => {
        if (isSuccess) onCloseClick()
    }, [isSuccess, onCloseClick])

    return (
        <>
            <div className={`${className} ${baseClassName}__create-button-container`}>
                <FilledButton textType="icon-text"
                    color={"blue"}
                    className={`${baseClassName}__create-button`}
                    onClick={onClickCreate}
                >
                    <MaterialSymbol name={"add"} /> Register
                </FilledButton>
            </div>

            <DialogPortal
                breakpoint="medium"
                type="basic"
                show={showDialog}
                className={`${baseClassName}`}
                headline="Register domain name"
                onCloseClick={onCloseClick}
                isLoading={isLoading}
                content={
                    <FormProvider {...form}>
                        <form onSubmit={onFormSubmit}>
                            <div className={`${baseClassName}__form`}>
                                {
                                    !MediaQueries.medium() &&
                                    <Typo.Headline size="small">Register domain name</Typo.Headline>
                                }

                                <div className={`${baseClassName}__form-inputs`}>
                                    <div className={`${baseClassName}__domain-inputs`}>
                                        <FormOutlinedTextField<RegisterDomainNameSchemaType>
                                            label="Second-level domain"
                                            name="sld"
                                            isLoading={isLoading}
                                            className={`${baseClassName}__sld`}
                                        />

                                        <FormMenu<string, RegisterDomainNameSchemaType>
                                            name="tld"
                                            label="TLD"
                                            labelSelector={v => v}
                                            valueSelector={v => v}
                                            options={tldOptions}
                                            isLoading={isLoading}
                                            className={`${baseClassName}__tld`}
                                            closeOnOptionClick
                                        />
                                    </div>

                                    <div className={`${baseClassName}__registrant`}>
                                        <RegistrantsFormMenu name={"registrantId"} />
                                    </div>
                                </div>

                                <div className={`${baseClassName}__buttons`}>
                                    <TextButton type="button" textType="text-only" color="blue" onClick={onCloseClick}>Cancel</TextButton>
                                    <TextButton type="submit" textType="text-only" color="blue">Register</TextButton>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                }
            />
        </>
    )
}

const useInitData = (customerId: string): RegisterDomainNameSchemaType => {
    return {
        customerId: customerId,
        tld: "",
        sld: "",
        registrantId: ""
    }
}

export default RegisterDomainNameDialog