import AppRoutes from "./routes/AppRoutes";
import './App.scss';
import Contexts from "../contexts/Contexts";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../stores/redux/store";

function App() {
    return (
        <Provider store={store}>
            <Contexts>
                <BrowserRouter>
                    <AppRoutes />
                </BrowserRouter>
            </Contexts>
        </Provider>
    );
}

export default App;
