import React, {FC} from "react";
import {useThemeContext} from "../../../../contexts/ThemeContext";
import Pane from "../../../../components/Pane/Pane";
import {MediaQueries} from "../../../../utils/MediaQueries";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import Tabs from "../../../../components/Tabs/Tabs";
import {RoutingDefinition} from "../../../../app/routes/RoutingDefinition";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import Tab from "../../../../components/Tabs/components/Tab";
import DomainNamesTab from "./components/DomainNamesTab/DomainNamesTab";
import RegistrantsTab from "./components/RegistrantsTab/RegistrantsTab";

const DomainsPage: FC = () => {
    const baseClassName = "domains-page"

    const {getThemeClassName} = useThemeContext()

    const location = useLocation()

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)} page-container`}>
            <Pane disableIfAccountNotComplete title="Domains">
                {
                    !MediaQueries.medium() &&
                    <div className={`${baseClassName}__table-options`}>
                        <PaneHeader title={"Domains"}/>
                    </div>
                }

                <Tabs routeDefinition={RoutingDefinition.domainNames}/>

                <AnimatePresence mode="wait">
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path="/"
                            element={<Navigate to={RoutingDefinition.domainNames.children![0].path} replace/>}
                        />

                        <Route
                            path={RoutingDefinition.domainNames.children![0].path}
                            element={Tab(DomainNamesTab)}
                        />

                        <Route
                            path={RoutingDefinition.domainNames.children![1].path}
                            element={Tab(RegistrantsTab)}
                        />
                    </Routes>
                </AnimatePresence>
            </Pane>
        </div>
    )
}

export default DomainsPage