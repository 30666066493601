import Menu from "components/Menu/Menu";
import { useMemo } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

interface IProps<TValue> {
    label: string
    name: string
    isLoading: boolean
    className?: string,
    labelSelector: (value: TValue) => string
    valueSelector: (value: TValue) => string
    options: TValue[]
    onSearch?: (value: string) => void,
    searchInterval?: number,
    closeOnOptionClick?: boolean,
    disabled?: boolean
}

function FormMenu<TValue, TSchema extends FieldValues>({
    label,
    name,
    isLoading,
    className,
    labelSelector,
    valueSelector,
    options,
    onSearch,
    searchInterval,
    closeOnOptionClick,
    disabled
}: IProps<TValue>) {
    const { watch, setValue } = useFormContext<TSchema>()

    const onChange = (value: TValue) => {
        setValue(
            name as Path<TSchema>,
            valueSelector(value) as PathValue<TSchema, Path<TSchema>>
        )
    }

    const value = watch(name as Path<TSchema>)

    const selected = useMemo(() => {
        let option = options.filter(o => valueSelector(o) === value)

        if (option.length === 0) return undefined

        return option[0]
    }, [value])

    return (
        <Menu<TValue>
            isLoading={isLoading}
            name={name}
            label={label}
            className={className}
            labelSelector={labelSelector}
            valueSelector={valueSelector}
            selected={selected}
            options={options}
            onChange={onChange}
            onSearch={onSearch}
            searchInterval={searchInterval}
            closeOnOptionClick={closeOnOptionClick}
            disabled={disabled}
        />
    )
}

export default FormMenu