import {
    DetailedHTMLProps,
    forwardRef,
    ReactNode,
    SelectHTMLAttributes, useRef, useMemo, useState, useEffect,
    useImperativeHandle
} from "react";

import {useThemeContext} from "../../../contexts/ThemeContext";
import {ValidationErrorTransformer} from "../../../utils/ValidationErrorTransformer";
import MaterialSymbol from "../../MaterialSymbol/MaterialSymbol";
import {useOutsideClickDetection} from "../../../hooks/useOutsideComponentDetection";

interface IProps extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    label: string
    options: { label: string, value: string | number }[]
    errors?: string[] | string
    disabled?: boolean
    supportingText?: ReactNode
}

const OutlinedTextFieldMenu = forwardRef<HTMLSelectElement, IProps>((
    {
        label,
        supportingText,
        errors,
        className,
        defaultValue,
        options,
        disabled = false,
        ...props
    },
    ref
) => {
    const {getThemeClassName} = useThemeContext()

    const baseClassName = "outlined-text-field-menu"

    const optionsRef = useRef<HTMLOptionElement[]>([])
    const containerRef = useRef<HTMLDivElement>(null)
    const localRef = useRef<HTMLSelectElement>(null)

    const [open, setOpen] = useState<boolean>(false)

    useOutsideClickDetection(containerRef, () => {
        setOpen(false)
    })

    useImperativeHandle(ref, () => localRef.current!, [])

    const onOptionClick = (index: number) => {
        if (ref && localRef.current) {
            localRef.current.value = options[index].value.toString()
            setOpen(false)
            localRef.current.dispatchEvent(new Event("change", {bubbles: true}))
        }
    }

    const selectedIndex = useMemo(() => {
        return options.findIndex(o => o.value === props.value)
    }, [props.value, options])

    return (
        <div
            className={`
                ${baseClassName}
                ${getThemeClassName(baseClassName)}
                ${disabled && baseClassName + "--disabled"} 
                ${(errors && errors.length > 0) && baseClassName + "--error"}
                ${className}
            `}
            ref={containerRef}
        >
            <div className={`${baseClassName}__input-container`}>
                <div className={`${baseClassName}__container`}>
                    <select className={`${baseClassName}__input`}
                            disabled={disabled}
                            ref={ref}
                            {...props}
                    >
                        {
                            options.map((option, index) => (
                                <option value={option.value}
                                        key={option.value}
                                        ref={el => el != null ? optionsRef.current[index] = el : null}
                                >
                                    {option.label}
                                </option>
                            ))
                        }
                    </select>

                    <button className={`
                                ${baseClassName}__button
                                ${open && `${baseClassName}__button--open`}
                            `}
                            type={"button"}
                            onClick={() => setOpen(!open)}
                    >
                        {options.find(o => o.value === props.value)?.label ?? "Select..."}

                        <MaterialSymbol name={"keyboard_arrow_down"}/>
                    </button>

                    <div
                        className={`
                            ${baseClassName}__options-container
                            ${open && `${baseClassName}__options-container--open`}
                        `}
                        ref={containerRef}
                    >
                        {
                            options.map((option, index) => (
                                <div className={`
                                        ${baseClassName}__option 
                                        ${index === selectedIndex && `${baseClassName}__option--selected`}
                                    `}
                                     key={"visible-" + option.value}
                                     onClick={() => onOptionClick(index)}
                                >
                                    {option.label}

                                    <MaterialSymbol name={"check"}/>
                                </div>
                            ))
                        }
                    </div>

                    <div className={`${baseClassName}__outline`}>
                        <div className={`${baseClassName}__label`}>
                            <p>{label}</p>
                        </div>
                    </div>
                </div>

                {
                    errors && (
                        Array.isArray(errors) ?
                            errors.map(e => (
                                <div className={`${baseClassName}__error-text`}
                                     key={props.name + e}>{ValidationErrorTransformer.transform(label, e)}</div>
                            ))
                            :
                            <div className={`${baseClassName}__error-text`}
                                 key={props.name}>{ValidationErrorTransformer.transform(label, errors)}</div>
                    )
                }
                <div className={`${baseClassName}__supporting-text`}>{supportingText}</div>
            </div>
        </div>
    )
})

export default OutlinedTextFieldMenu