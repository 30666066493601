import {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from "react";

interface ISidebarContext {
    isOpen: boolean
    toggleSidebar: () => void
    closeSidebar: () => void
}

export const SidebarContext = createContext({} as ISidebarContext)

const localSidebarState = localStorage.getItem("sidebar")

export const SidebarContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [isOpen, setIsOpen] = useState<boolean>(parseInt(localSidebarState ?? "1") === 1)

    const toggleSidebar = () => setIsOpen(value => !value)
    const closeSidebar = () => setIsOpen(false)

    useEffect(() => {
        localStorage.setItem("sidebar", isOpen ? "1" : "0")
    }, [isOpen])

    return (
        <SidebarContext.Provider
            value={{
                isOpen,
                toggleSidebar,
                closeSidebar
            }}
        >
            {children}
        </SidebarContext.Provider>
    )
}

export const useSidebar = () => useContext(SidebarContext)