import React, {FC, useEffect, useState} from "react";
import Pane from "../../../../components/Pane/Pane";
import {useThemeContext} from "../../../../contexts/ThemeContext";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import {useNavigate, useParams} from "react-router-dom";
import {RoutingDefinition} from "../../../../app/routes/RoutingDefinition";
import {useGetVdcQuery, useUpdateVdcMutation} from "../../redux/vdcsApi";
import VdcForm from "../../components/VdcForm/VdcForm";
import Loader from "../../../../components/Loader/Loader";
import {useAppSelector} from "../../../../stores/redux/hooks/useSelector";
import {useAppForm} from "../../../../hooks/useAppForm";
import {FormProvider} from "react-hook-form";
import {UpdateVdcRequest, UpdateVdcRequestSchema} from "../../models/UpdateVdcRequest";

interface IProps {

}

const EditVdcPage: FC<IProps> = () => {
    const {id} = useParams()

    const baseClassName = "edit-vdc-page"
    const {getThemeClassName} = useThemeContext()

    const navigate = useNavigate()

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {
        data,
        isLoading: isLoadingVdc
    } = useGetVdcQuery({
        customerId: customerId ?? "",
        vdcId: id ?? ""
    }, {
        skip: id === "" || !customerId || customerId === ""
    })

    const [initData] = useState<UpdateVdcRequest>({
        customerId: customerId ?? "",
        id: data?.id ?? "",
        name: data?.name ?? "",
        cpuLimit: data?.cpuLimit ?? 0,
        memoryLimit: data?.memoryLimit ?? 0,
        storageLimit: data?.storageLimit ?? 0,
        ipLeases: data?.ipLeases ?? 0
    })

    const onBackClick = () => navigate(`/vdcs/${id}`)

    const {
        form,
        onFormSubmit,
        isLoading,
        isSuccess
    } = useAppForm(
        initData,
        UpdateVdcRequestSchema,
        useUpdateVdcMutation
    )

    useEffect(() => {
        if (isSuccess) navigate(`${RoutingDefinition.vdcs.path}/${data?.id}`)
    }, [isSuccess, navigate, data?.id]);


    if (!data || isLoadingVdc) return (
        <div className={`${baseClassName}`}>
            <Pane title="Private Cloud">
                <div className={`${baseClassName}__loader`}><Loader/></div>
            </Pane>
        </div>
    )

    return (
        <div className={`
            ${baseClassName}
            ${getThemeClassName(baseClassName)}
        `}>
            <Pane
                className={`${baseClassName}__pane`}
                disableIfAccountNotComplete
                showDisabledText={true}
                isLoading={isLoading}
                title="Private Cloud"
            >
                <PaneHeader
                    backButton
                    onBackClick={onBackClick}
                    parentTitle={"Private Cloud"}
                    title={data.name}
                />

                <FormProvider {...form}>
                    <VdcForm
                        onSubmit={onFormSubmit}
                        isLoading={isLoading}
                        onCancel={() => navigate(`${RoutingDefinition.vdcs.path}`)}
                        name={data.name}
                    />
                </FormProvider>
            </Pane>
        </div>
    )
}

export default EditVdcPage