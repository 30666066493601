import React, {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";
import {motion} from "framer-motion";
import {Link, Outlet, useLocation} from "react-router-dom";
import {IRouteDefinition} from "../../app/routes/RoutingDefinition";

interface IProps {
    routeDefinition: IRouteDefinition
}

const Tabs: FC<IProps> = ({routeDefinition}) => {
    const baseClassName = "tabs-outlet"

    const labelRefs = useRef<HTMLAnchorElement[]>([])

    const location = useLocation()

    const selected = useMemo(() => routeDefinition.children!.findIndex(t => location.pathname.includes(t.path)), [routeDefinition, location.pathname])
    const [left, setLeft] = useState(0)
    const [width, setWidth] = useState(0)

    const {getThemeClassName} = useThemeContext()

    const setPosition = useCallback(() => {
        let parent = labelRefs.current[selected]

        if (!parent) return

        setWidth(parent.getBoundingClientRect().width / 2)
        setLeft(parent.getBoundingClientRect().width / 4 + parent.offsetLeft)
    }, [selected])

    useEffect(() => {
        let obs = new ResizeObserver(() => setPosition())

        if (labelRefs.current[selected]) obs.observe(labelRefs.current[selected])

        return () => obs.disconnect()
    }, [selected, setPosition])

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <div className={`${baseClassName}__header`}>
                <div className={`${baseClassName}__labels`}>
                    {routeDefinition.children!.map((tab, index) => {
                            return (
                                <Link
                                    className={`${baseClassName}__label ${index === selected && `${baseClassName}__label--selected`}`}
                                    key={`tab-${index}`}
                                    ref={el => el != null ? labelRefs.current[index] = el : null}
                                    to={tab.navigationLink!}
                                >
                                    <p>{tab.name}</p>
                                </Link>
                            )
                        }
                    )}
                </div>

                <motion.span
                    className={`${baseClassName}__divider`}
                    animate={{
                        left,
                        width,
                    }}
                    transition={{
                        type: "spring"
                    }}
                />
            </div>

            <Outlet/>
        </div>
    )
}

export default Tabs