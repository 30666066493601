import {ValidationError} from "../types/enums/ValidationError";

export class ValidationErrorTransformer {
    static transformErrorCodes(label: string, errors: string[]) {
        return errors.map(e => this.transform(label, e))
    }

    static transform(label: string, error: string) {
        if (error.startsWith(ValidationError.Min)) {
            return `${label.replace("*", "")} must be at least ${error.split(ValidationError.Min)[1]}`
        }

        if (error.startsWith(ValidationError.Max)) {
            return `${label.replace("*", "")} must not be higher than ${error.split(ValidationError.Max)[1]}`
        }

        if (error.startsWith(ValidationError.CharMin)) {
            return `${label.replace("*", "")} must be at least ${error.split(ValidationError.CharMin)[1]} characters long`
        }

        if (error.startsWith(ValidationError.CharMax)) {
            return `${label.replace("*", "")} must not be container more than ${error.split(ValidationError.CharMax)[1]} characters`
        }

        if (error.startsWith(ValidationError.Required)) {
            return `${label.replace("*", "")} is required`
        }

        if (error.startsWith(ValidationError.Taken)) {
            return `${label.replace("*", "")} is in use`
        }

        if (error.startsWith(ValidationError.NotEmpty)) {
            return `${label.replace("*", "")} can not be empty`
        }

        return "The given value is invalid"
    }
}