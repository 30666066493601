import React, {FC, useMemo} from "react";
import {useThemeContext} from "../../../../contexts/ThemeContext";
import {useSidebar} from "../../../../contexts/SidebarContext";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import {RoutingDefinition} from "../../../routes/RoutingDefinition";
import AppLink from "../../../../components/AppLink/AppLink";

const Sidebar: FC = () => {
    const {getThemeClassName} = useThemeContext()
    const {isOpen, toggleSidebar} = useSidebar()

    const baseClassName = "sidebar";

    const className = useMemo(() => {
        return baseClassName + (isOpen ? ` ${baseClassName}--open` : ` ${baseClassName}--closed`)
    }, [isOpen])

    return (
        <div className={`${className} ${getThemeClassName(baseClassName)}`}>
            <div className={`${baseClassName}__close`}>
                <TextButton color="blue" textType="icon-only" onClick={toggleSidebar}>
                    <MaterialIcon name="close"/>
                </TextButton>
            </div>

            <div className={`${baseClassName}__menu-icon`}>
                <TextButton
                    color='blue'
                    onClick={() => toggleSidebar()}
                    textType="icon-only"
                >
                    <MaterialIcon name={isOpen ? "menu_open" : "menu"}/>
                </TextButton>
            </div>

            <div className={`${baseClassName}__routes`}>
                {
                    Object
                        .entries(RoutingDefinition)
                        .filter(([_, def]) => def.sidebar)
                        .map(([_, def]) => (
                            <div className={`${baseClassName}__route-container`} key={`route__${def.path}`}>
                                <AppLink to={def.navigationLink ? def.navigationLink : def.path}>
                                    <div
                                        className={`
                                            ${baseClassName}__route
                                            
                                        `}
                                    >
                                        <MaterialIcon
                                            type={def.iconType ?? "round"}
                                            name={def.iconName!}
                                            className={`${baseClassName}__route-icon`}
                                        />

                                        <p className={`${baseClassName}__route-name`}>{def.name}</p>
                                    </div>
                                </AppLink>
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default Sidebar