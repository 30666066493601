import StatusIndicator from "../StatusIndicator/StatusIndicator";
import React, {FC} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

interface IProps {
    status: string;
    small?: boolean;
}

const StatusChip: FC<IProps> = ({status, small}) => {
    const baseClassName = "status-chip";

    const {getThemeClassName} = useThemeContext()

    return (
        <div className={`
                ${baseClassName}
                ${baseClassName}--${status.toLowerCase()}
                ${small && `${baseClassName}--small`}
                ${getThemeClassName(baseClassName)}`
        }>
            <StatusIndicator status={status}/><p>{status.charAt(0).toUpperCase()}{status.slice(1)}</p>
        </div>
    )
}

export default StatusChip;