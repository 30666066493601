import { api } from "../../../stores/redux/reducers/api";
import { DomainName } from "./responses/DomainName";
import { Paginated } from "../../../types/models/Paginated";
import { GetDomainNameByCustomerId } from "./queries/GetDomainNameByCustomerId";
import { GetDomainNameByCustomerIdAndRegistrantId } from "./queries/GetDomainNameByCustomerIdAndRegistrantId";
import { CustomerRelatedRequest } from "types/models/CustomerRelatedRequest";
import { RegisterDomainNameSchemaType } from "./commands/RegisterDomainNameSchema";

const domainNamesApi = api.injectEndpoints({
    endpoints: build => ({
        getDomainNamesByCustomerId: build.query<Paginated<DomainName>, GetDomainNameByCustomerId>({
            query: ({
                customerId,
                page,
                pageSize
            }) => `customers/${customerId}/domain_names?page=${page}&pageSize=${pageSize}`,
            providesTags: (result) => result
                ? [...result.items.map(({ id }) => ({ type: "DomainNames" as const, id })), { type: "DomainNames", id: "LIST" }]
                : [{ type: "DomainNames", id: "LIST" }]
        }),
        getDomainNamesByCustomerIdAndRegistrantId: build.query<Paginated<DomainName>, GetDomainNameByCustomerIdAndRegistrantId>({
            query: ({
                customerId,
                registrantId,
                page,
                pageSize
            }) => `customers/${customerId}/registrants/${registrantId}/domain_names?page=${page}&pageSize=${pageSize}`,
            providesTags: (result) => result
                ? [...result.items.map(({ id }) => ({ type: "DomainNames" as const, id })), { type: "DomainNames", id: "LIST" }]
                : [{ type: "DomainNames", id: "LIST" }]
        }),
        getDomainNameById: build.query<DomainName, { domainNameId: string } & CustomerRelatedRequest>({
            query: ({
                customerId,
                domainNameId
            }) => `customers/${customerId}/domain_names/${domainNameId}`,
            providesTags: (data) => [{ type: "DomainNames", id: data?.id }]
        }),
        registerDomainName: build.mutation<DomainName, RegisterDomainNameSchemaType>({
            query: ({
                customerId,
                ...data
            }) => ({
                url: `customers/${customerId}/domain_names`,
                method: "POST",
                body: data
            }),
            invalidatesTags: [{ type: "DomainNames", id: "LIST" }]
        })
    })
})

export const {
    useGetDomainNamesByCustomerIdQuery,
    useGetDomainNamesByCustomerIdAndRegistrantIdQuery,
    useGetDomainNameByIdQuery,
    useRegisterDomainNameMutation
} = domainNamesApi