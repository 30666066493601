import React, {FC} from "react";
import {useGetVdcQuery} from "../../redux/vdcsApi";
import {useNavigate, useParams} from "react-router-dom";
import Pane from "../../../../components/Pane/Pane";
import PaneHeader from "../../../../components/PaneHeader/PaneHeader";
import Loader from "../../../../components/Loader/Loader";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import FilledButton from "../../../../components/Buttons/FilledButton/FilledButton";
import {RoutingDefinition} from "../../../../app/routes/RoutingDefinition";
import {useThemeContext} from "../../../../contexts/ThemeContext";
import Subheading from "../../../../components/Subheading/Subheading";
import Label from "../../../../components/Label/Label";
import {useAppSelector} from "../../../../stores/redux/hooks/useSelector";

interface IProps {

}

const VdcPage: FC<IProps> = () => {
    const baseClassName = "vdc-page"

    const {getThemeClassName} = useThemeContext()

    const {id} = useParams()

    const navigate = useNavigate()

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {data, isLoading} = useGetVdcQuery({
        customerId: customerId ? customerId : "",
        vdcId: id ?? ""
    }, {skip: id === "" || !customerId || customerId === ""})

    const onBackClick = () => navigate(RoutingDefinition.vdcs.navigationLink!)

    if (!data || isLoading) return (
        <div className={`${baseClassName}`}>
            <Pane title="Private Cloud">
                <div className={`${baseClassName}__loader`}><Loader/></div>
            </Pane>
        </div>
    )

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <Pane title="Private Cloud">
                <PaneHeader
                    backButton
                    onBackClick={onBackClick}
                    title={data.name}
                    parentTitle={"Private Cloud"}
                >
                    <FilledButton
                        color="blue"
                        textType="icon-text"
                        className={`${baseClassName}__create-vdc-button`}
                        onClick={() => navigate(`/vdcs/${id}/edit`)}
                    >
                        <MaterialIcon name="edit"/>
                        Update
                    </FilledButton>
                </PaneHeader>

                <Subheading><MaterialIcon name={"tune"}/> Quota</Subheading>
                <Label>CPU Limit</Label>
                <p className={`${baseClassName}__amount`}>{data.cpuLimit} core(s)</p>

                <Label>Memory Limit</Label>
                <p className={`${baseClassName}__amount`}>{data.memoryLimit} GB</p>

                <Label>Storage Limit</Label>
                <p className={`${baseClassName}__amount`}>{data.storageLimit} GB</p>

                <Label>IP leases</Label>
                <p className={`${baseClassName}__amount`}>{data.ipLeases} lease(s)</p>
            </Pane>
        </div>
    )
}

export default VdcPage