import {FC} from "react";
import {useThemeContext} from "../../contexts/ThemeContext";

interface IProps {
    text: string
}

const ToolTip: FC<IProps> = ({text}) => {
    const {getThemeClassName} = useThemeContext()

    const baseClassName = "tool-tip"

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <p>{text}</p>
        </div>
    )
}

export default ToolTip