import FormMenu from "components/Form/components/FormMenu";
import FormOutlinedTextField from "components/Form/components/FormOutlinedTextField";
import { DnsRecordSchemaType } from "features/Domains/api/commands/DnsRecordSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

interface IProps {
    isLoading: boolean,
    isExistingRecord: boolean
}

const DnsRecordInputs: FC<IProps> = ({
    isLoading,
    isExistingRecord
}) => {
    const { watch } = useFormContext<DnsRecordSchemaType>()

    const baseClassName = "dns-record-inputs"

    const type = watch("type")

    const recordOptions = [
        "A",
        "AAAA",
        "CNAME",
        "MX",
        "TXT",
        "NS",
        "PTR",
        "SRV"
    ]

    return (
        <>
            <FormMenu<string, DnsRecordSchemaType>
                name="type"
                label="Type"
                labelSelector={v => v}
                valueSelector={v => v}
                options={recordOptions}
                isLoading={isLoading}
                className={`${baseClassName}__type`}
                closeOnOptionClick
                disabled={isExistingRecord}
            />

            <FormOutlinedTextField label={"Host"} name={"host"} isLoading={isLoading} />
            <FormOutlinedTextField label={"Content"} name={"content"} isLoading={isLoading} />
            <FormOutlinedTextField label={"TTL"} name={"ttl"} isLoading={isLoading} />

            {
                (type === "SRV" || type === "MX") && (
                    <FormOutlinedTextField label={"Priority"} name={"priority"} isLoading={isLoading} />
                )
            }

            {
                type === "SRV" && (
                    <>
                        <FormOutlinedTextField label={"Weight"} name={"weight"} isLoading={isLoading} />
                        <FormOutlinedTextField label={"Port"} name={"port"} isLoading={isLoading} />
                    </>
                )
            }
        </>
    )
}

export default DnsRecordInputs