import { FC, useMemo } from 'react'
import { Invoice } from "../../../../models/Invoice";
import MaterialIcon from "../../../../../../components/MaterialIcon/MaterialIcon";
import { DateHelper } from "../../../../../../utils/DateHelper";
import Table from "../../../../../../components/Table/Table";
import { useAppSelector } from "../../../../../../stores/redux/hooks/useSelector";
import {
    useGetCustomerInvoicesPaginatedQuery
} from "../../../../redux/invoicesApi";
import { useNavigate, useSearchParams } from "react-router-dom";

interface IProps {
}

const Invoices: FC<IProps> = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const pageSize = 10
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const baseClassName = "billing-page-invoices"

    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useGetCustomerInvoicesPaginatedQuery({
        customerId: customerId ?? "",
        page: page,
        pageSize: pageSize
    }, {
        skip: !customerId,
    })

    const onClickInvoice = (id: string) => navigate(`/invoices/${id}`)

    return (
        <div className={`${baseClassName}`}>
            <Table<Invoice>
                className={`${baseClassName}__table`}
                columns={[
                    {
                        name: "Number",
                        render: (invoice) => (
                            <p>{invoice.number}</p>
                        ),
                        className: `${baseClassName}__number`
                    },
                    {
                        name: "Status",
                        render: (invoice) => {
                            return invoice.paid ? (
                                <div className={`
                                        ${baseClassName}__paid-icon
                                        ${baseClassName}__paid-icon--paid`}
                                >
                                    <MaterialIcon name={"attach_money"} /> Paid
                                </div>
                            ) : (
                                <div className={`
                                        ${baseClassName}__paid-icon
                                        ${baseClassName}__paid-icon--unpaid`}
                                >
                                    <MaterialIcon name={"money_off"} /> Unpaid
                                </div>
                            )
                        },
                        className: `${baseClassName}__status`
                    },
                    {
                        name: "Total",
                        render: (invoice) => (
                            <div className={`
                                        ${baseClassName}__total
                                    `}>
                                <p>€{invoice.total.toFixed(2)}</p>
                            </div>
                        ),
                        breakpoint: "medium",
                        className: `${baseClassName}__status`,
                    },
                    {
                        name: "Period",
                        render: (invoice) => (
                            invoice.periodStart && invoice.periodEnd
                                ?
                                <p>{DateHelper.getDateString(new Date(invoice.periodStart))} - {DateHelper.getDateString(new Date(invoice.periodEnd))}</p>
                                : <p>-</p>
                        ),
                        breakpoint: "medium",
                        className: `${baseClassName}__period`
                    },
                    {
                        name: "Due",
                        render: (invoice) => (
                            invoice.paid ?
                                <p>-</p>
                                :
                                <p>Due in {DateHelper.daysUntil(new Date(invoice.dueDate))} day(s)</p>
                        ),
                        className: `${baseClassName}__due-date`
                    },
                    {
                        name: "Created at",
                        render: (invoice) => (
                            <p>{DateHelper.getDateString(new Date(invoice.createdAt))}</p>
                        ),
                        breakpoint: "expanded",
                        className: `${baseClassName}__created-at`
                    }
                ]}
                onItemClick={(item) => onClickInvoice(item.id)}
                items={data}
                page={page}
                pageSize={pageSize}
                totalItems={data?.totalItems ?? 0}
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                setSearchParamsOnChange
            />
        </div>
    )
}

export default Invoices