import {useSearchParams} from "react-router-dom";
import React from "react";
import Skeleton from "../Skeleton/Skeleton";
import MaterialIcon from "../MaterialIcon/MaterialIcon";
import ReactPaginate from "react-paginate";
import {Paginated} from "../../types/models/Paginated";
import {useThemeContext} from "../../contexts/ThemeContext";
import {RandomGenerator} from "../../utils/RandomGenerator";
import {MediaQueries} from "../../utils/MediaQueries";

interface Column<TItem> {
    name: string,
    render: (item: TItem) => any,
    /*
    Indicates from which screen size the column has to be shown
     */
    breakpoint?: "compact" | "medium" | "expanded" | "large" | "extraLarge"
    className?: string | ((item: TItem) => string),
    headerClassName?: string
    clickable?: boolean
}

interface IProps<TItem> {
    className: string
    columns: Column<TItem>[]
    items?: Paginated<TItem>
    page: number
    pageSize: number
    totalItems: number
    isLoading: boolean
    isFetching: boolean
    isError?: boolean
    error?: string
    onItemClick?: (item: TItem) => any
    onPageChange?: (selected: number) => void
    setSearchParamsOnChange?: boolean
    renderHeader?: boolean
}

function Table<TItem>(
    {
        className,
        columns,
        items,
        page,
        pageSize,
        totalItems,
        isLoading,
        isFetching,
        isError,
        error,
        onItemClick,
        onPageChange,
        setSearchParamsOnChange = true,
        renderHeader = true
    }: IProps<TItem>
) {
    const [, setSearchParams] = useSearchParams()
    const {getThemeClassName} = useThemeContext()

    const setPage = (selected: number) => {
        if (onPageChange) onPageChange(selected)

        if (setSearchParamsOnChange) {
            setSearchParams((prev) => [
                ...Array.from(prev)
                    .filter(
                        ([key]) => key !== "page" && key !== "pageSize"
                    ),
                ["page", `${selected}`],
                ["pageSize", `${pageSize}`]
            ])
        }
    }

    const onClick = (e: React.MouseEvent<HTMLDivElement>, item: TItem) => {
        onItemClick && onItemClick(item)
    }

    return (
        <div className={`table ${className} ${getThemeClassName("table")} ${isFetching && "table--fetching"}`}>
            <div className="table__table-container">
                {
                    renderHeader &&
                    <div
                        className="table__row table__row--header"
                    >
                        {
                            columns.map(column => (
                                <div
                                    className={`
                                        table__column
                                        table__column--header
                                        ${column.breakpoint && `table__column--${MediaQueries[column.breakpoint]() ? "hidden" : "visible"}`}
                                        ${column.headerClassName ?? column.className}
                                    `}
                                    key={`table-header-${column.name}`}
                                >
                                    <p>{column.name}</p>
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    items?.items.length === 0 && !isLoading && !isError &&
                    <p className="table__empty-text">No items to display.</p>
                }

                {
                    ((!items && isLoading) || isLoading) &&
                    <div className="table__rows">
                        {
                            RandomGenerator.getArrayOfRandomLength(10, 10).map((_, rowIndex) => (
                                <div className="table__row" key={`skeleton-row-${rowIndex}`}>
                                    {
                                        columns.map((column, columnIndex) => {
                                            return (
                                                <div
                                                    className={`
                                                        table__column
                                                        ${column.breakpoint && `table__column--${MediaQueries[column.breakpoint]() ? "hidden" : "visible"}`}
                                                        ${column.className}
                                                    `}
                                                    key={`table-column-${column.name}`}
                                                >

                                                    <Skeleton
                                                        width={RandomGenerator.getRandomNumber(0.1, 1, 2) * 100 + "%"}
                                                        animationDelay={((columnIndex * 100) + (rowIndex * 100)) + "ms"}
                                                    >
                                                        <p>p</p>
                                                    </Skeleton>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    items && items.items.length > 0 && !isLoading && (
                        <div className="table__rows">
                            {
                                items.items.map((item, index) => (
                                    <div
                                        className="table__row"
                                        key={`table-row-${index}`}
                                    >
                                        {
                                            columns.map(column => (
                                                <div
                                                    className={`
                                                    table__column
                                                    ${column.breakpoint && `table__column--${MediaQueries[column.breakpoint]() ? "hidden" : "visible"}`}
                                                    ${typeof column.className === "function" ? column.className(item) : column.className}`
                                                    }
                                                    onClick={column.clickable === false || isFetching ? undefined : (e) => onClick(e, item)}
                                                    key={`table-column-${column.name}`}
                                                >
                                                    {column.render(item)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>

            {
                isError && (!items || items?.items.length === 0) &&
                <div className="table__error">
                    <MaterialIcon name="error_outline" type="outlined"/>
                    {
                        error ?
                            <p>{error}</p> :
                            <p>An error occurred when trying to fetch the data. Please refresh the page or try again
                                later.</p>
                    }
                </div>
            }

            {
                !isLoading &&
                Math.ceil(totalItems / pageSize) !== 0 &&
                <ReactPaginate
                    className="table__pagination"
                    pageCount={Math.ceil(totalItems / pageSize)}
                    forcePage={page}
                    previousLabel={<MaterialIcon name="chevron_left"/>}
                    nextLabel={<MaterialIcon name="chevron_right"/>}
                    onPageChange={
                        isFetching
                            ? () => {
                            }
                            : (selectedItem) => setPage(selectedItem.selected)
                    }
                    renderOnZeroPageCount={null}
                    disableInitialCallback
                    marginPagesDisplayed={1}
                />
            }
        </div>
    );
}

export default Table