import React, {FC} from "react";
import Typo from "../../../../../components/Typo/Typo";
import RadioButton from "../../../../../components/RadioButton/RadioButton";
import {useThemeContext} from "../../../../../contexts/ThemeContext";
import TextButton from "../../../../../components/Buttons/TextButton/TextButton";
import MaterialSymbol from "../../../../../components/MaterialSymbol/MaterialSymbol";
import {useSideSheet} from "../../../../../contexts/SideSheetContext";

interface IProps {

}

const Settings: FC<IProps> = () => {
    const baseClassName = "app-settings"

    const {theme, setTheme, getThemeClassName} = useThemeContext()

    const onChangeTheme = (value: string) => setTheme(value)

    const {closeSideSheet} = useSideSheet()

    return (
        <div
            key={baseClassName}
            className={`${baseClassName} ${getThemeClassName(baseClassName)}`}
        >
            <div className={`${baseClassName}__header`}>
                <Typo.Display size={"small"}>Settings</Typo.Display>

                <TextButton
                    color={"blue"}
                    textType={"icon-only"}
                    className={`${baseClassName}__close`}
                    onClick={closeSideSheet}
                >
                    <MaterialSymbol name={"close"}/>
                </TextButton>
            </div>

            <div className={`${baseClassName}__theme app-settings__container`}>
                <Typo.Title size={"medium"}>Theme</Typo.Title>

                <RadioButton
                    value="light"
                    label="Light"
                    onChange={onChangeTheme}
                    selected={theme}
                />
                <RadioButton
                    value="dark"
                    label="Dark"
                    onChange={onChangeTheme}
                    selected={theme}
                />
            </div>
        </div>
    )
}

export default Settings